
import {Component} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import {JobService} from "@/data";
import {Action} from "vuex-class";
import {StoreAction} from "@/types";
import {showModal} from "@/helpers/callables";
import RedirectionModal from "@/components/ats/modal/RedirectionModal.vue";
import ToggleSwitch from '@/components/ToggleSwitch.vue';

@Component({
    name: "JobServices",
    components: { RedirectionModal,
                  ToggleSwitch  }
})
export default class JobServices extends mixins(VModel) {
    loading = false;
    showRedirectionModal = false;
    JobService = JobService;

    @Action('actions/postServiceJobOffer') postServiceJobOffer!: StoreAction;

    get servicesName() {
        return Object.keys(this.JobService).filter((key) => key == "talentplug" );
    }

    get services() {
        return this.innerValue;
    }

    get jobOfferId() {
        return this.$route.params.jobOfferId;
    }

    get isActive() {
        return this.innerValue['talentplug'].active;
    }

    toggle(context:string) {
        if (!this.innerValue[context]) {
            this.innerValue[context] = {};
        }
        this.innerValue[context].active = !this.innerValue[context].active;
        this.toggleService(context);
    }

    async toggleService(context: string) {
        this.loading = true;
        this.$emit("toggle", this.loading);
        try {
            const res = await this.postServiceJobOffer({
                jobOfferId: this.jobOfferId,
                toggle: this.innerValue[context].active ? 'post' : 'unpost',
                data: {
                    service: this.innerValue
                }
            });
            if (res.offerUrl) {
                showModal.call(this, 'RedirectionModal');
                location.href = res.offerUrl;
            }
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
        this.$emit("toggle", this.loading);
    }
}
