
import { Component, Prop } from "vue-property-decorator";
import JobServices from "@/components/ats/JobOffer/JobServices.vue";
import { mixins } from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import ListAutocompleteSelect from "@/components/ListAutocompleteSelect.vue";
import { LabelValue, StoreMutation, ICompanyDB, IMissionDB, StoreAction, applicantDegree, applicantExperience } from "@/types";
import { Getter, Action, Mutation, State } from "vuex-class";
import InlineField from "@/components/InlineField.vue";
import { capitalize } from "@/helpers/commons";
import clonedeep from "lodash.clonedeep";
import { ROUTES } from "@/data";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";
import Pulse from "@/components/loader/Pulse.vue";
import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
import CheckboxCard from "@/components/form/CheckboxCard.vue";
import slugify from "slugify";
import Form from "@/dsComponents/Form/Form.vue";
import FormSection from "@/dsComponents/FormSection.vue";
import Link from "@/dsComponents/buttons/Link.vue";

@Component({
    name: "JobOfferForm",
    components: {
        InlineField,
        ListAutocompleteSelect,
        SelectAutocomplete,
        JobServices,
        Pulse,
        InputGmapAutocomplete,
        CheckboxCard,
        Form,
        FormSection,
        Link,
    },
})
export default class JobOfferForm extends mixins(VModel) {
    i18n: any = this.$t("page.jobOffer.add.form");
    missions: Array<IMissionDB> = [];
    clients: ICompanyDB | any = [];
    selectMission: any = [];
    selectedClient: any = [];
    selectJobIndustry: any = [];
    selectJobFunction: any = [];
    selectApplicantDegree: any = [];
    selectApplicantExperience: any = [];
    loading: boolean = false;
    jobIndustries: any = [];
    jobFunctions: any = [];
    ROUTES = ROUTES;

    @Prop({
        default: {},
    })
    errors!: any;
    @Prop({ default: true }) showBackButton!: boolean;
    @Prop({ default: true }) showTitle!: boolean;

    @Getter("jobOptions") jobOptions!: LabelValue[];
    @Getter("talentplugApplicantDegree") talentplugApplicantDegree!: [];
    @Getter("talentplugApplicantExperience") talentplugApplicantExperience!: [];
    @Getter("getJob") getJob!: any;
    @Mutation("setMissionDataForJobOffer") setMissionDataForJobOffer!: StoreMutation;
    @State("missionDataForJobOffer") missionDataForJobOffer!: any;
    @Getter("businessSectorOptions") businessSectorOptions!: LabelValue[];

    @Action("actions/getMissions") getMissions!: StoreAction;
    @Action("actions/loadCompanies") loadCompanies!: StoreAction;
    @Action("actions/loadTalentplugData") loadTalentplugData!: StoreAction;

    get isCreate() {
        return !this.innerValue._id;
    }

    get formTitle() {
        return !this.innerValue._id ? this.$t("page.jobOffer.add.title") : this.$t("page.jobOffer.edit.title");
    }

    get missionName() {
        return this.innerValue.missionName || this.innerValue.mission?.name;
    }

    get clientsOption() {
        if (!this.clients?.rows?.length) return [];
        return this.clients.rows.map((company: any) => {
            const label = company.name ?? "";
            const value = company._id ?? "";
            const slug = company.slug ?? slugify(company.name, { lower: true });
            return { label, value, slug };
        });
    }
    get jobIndustriesOption() {
        if (!this.jobIndustries?.length) return [];
        return this.jobIndustries.map((jobIndustry: any) => {
            const label = jobIndustry.job_industry ?? "";
            const value = jobIndustry._id ?? "";
            return { label, value };
        });
    }

    get jobFunctionsOption() {
        if (!this.jobFunctions?.length) return [];
        return this.jobFunctions.map((jobFunction: any) => {
            const label = jobFunction.job_function ?? "";
            const value = jobFunction._id ?? "";
            return { label, value };
        });
    }

    get hasServices() {
        return this.innerValue.services && Object.keys(this.innerValue.services).length;
    }

    get fromMission() {
        return this.$route.query.fromMission === "1";
    }

    get hasMission() {
        return (this.fromMission || this.innerValue.mission) && this.missionName;
    }

    get jobOfferCity() {
        return this.innerValue.address.city;
    }

    goToMission() {
        this.$router.push({ name: ROUTES.APP.MISSION.DETAIL, params: { missionId: this.innerValue.missionId } });
    }

    goToback() {
        this.$router.back();
    }

    placeChanged(place: any) {
        this.innerValue.address.city = place.locality;
        this.innerValue.address.zip = place.postal_code;
    }

    onChange(data: any, propertyName: string) {
        if (["functions", "businessSectors"].includes(propertyName)) {
            this.innerValue[propertyName] = [];
            if (data[0].value) this.innerValue[propertyName] = [data[0].value];
            return;
        }

        this.innerValue[propertyName] = data && data[0] && data[0].value;
    }

    async init() {
        try {
            this.clients = await this.loadCompanies();
            const talentplugData = await this.loadTalentplugData();
            this.jobIndustries = talentplugData.businessSectors;
            this.jobFunctions = talentplugData.functions;
        } catch (e) {
            console.error(e);
        }
    }

    beforeMount() {
        if (this.fromMission) {
            const m = clonedeep(this.missionDataForJobOffer);
            this.setMissionDataForJobOffer(null);

            this.innerValue.name = m.name;
            this.innerValue.address = m.address;
            this.innerValue.start = m.start;
            this.innerValue.end = m.end;
            this.innerValue.applicantProfile = m.applicantProfile;
            this.innerValue.description = m.description;
            this.innerValue.missionName = m.missionName;
            this.innerValue.missionId = m.missionId;
            this.innerValue.jobId = m.jobId;
            this.innerValue.jobName = m.jobName;
            this.innerValue.client = {
                _id: m.clientId,
                name: m.clientName,
                nameSlug: m.clientNameSlug,
            };
        }
        if (this.innerValue.client) {
            this.selectedClient = [
                {
                    label: this.innerValue.client.name,
                    value: this.innerValue.client._id,
                    slug: this.innerValue.client.nameSlug,
                },
            ];
        }
        if (this.innerValue.functionFull) {
            this.selectJobFunction = [
                {
                    label: this.innerValue.functionFull.job_function,
                    value: this.innerValue.functionFull._id,
                },
            ];
        }
        if (this.innerValue.applicantDegree && applicantDegree[this.innerValue.applicantDegree]) {
            this.selectApplicantDegree = [
                {
                    label: applicantDegree[this.innerValue.applicantDegree].label,
                    value: this.innerValue.applicantDegree,
                },
            ];
        }
        if (this.innerValue.applicantExperience && applicantExperience[this.innerValue.applicantExperience]) {
            this.selectApplicantExperience = [
                {
                    label: applicantExperience[this.innerValue.applicantExperience].label,
                    value: this.innerValue.applicantExperience,
                },
            ];
        }
        if (this.innerValue.businessSectorFull) {
            this.selectJobIndustry = [
                {
                    label: this.innerValue.businessSectorFull.job_industry,
                    value: this.innerValue.businessSectorFull._id,
                },
            ];
        }
        if (this.innerValue.jobId && this.innerValue.jobName) {
            this.selectMission = [
                {
                    label: capitalize(this.innerValue.jobName),
                    value: this.innerValue.jobId,
                },
            ];
        }
        this.init();
    }
}
