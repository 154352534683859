import { render, staticRenderFns } from "./CheckboxCard.vue?vue&type=template&id=7f9fa1fb&scoped=true&"
import script from "./CheckboxCard.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CheckboxCard.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CheckboxCard.vue?vue&type=style&index=0&id=7f9fa1fb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f9fa1fb",
  null
  
)

export default component.exports