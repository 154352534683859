import {
    Brand,
    CandidateLogType,
    Feature,
    MessageTemplateType,
    NotificationLevel,
    NotificationStatus,
    NotificationType,
    TeamPreference,
    ECandidateOfferStatus,
    ESearchWorkerModes,
    ELogType,
    COLLECTIONS,
    ECandidateContact,
    ECandidateRole,
    EWorkerDatabaseOrigin,
    EWorkerCandidateStatus,
    JobOfferCommand,
} from "@/data";

export interface IRootState {
    auth?: {
        token: string;
    };
    lastViewFilters: any;
    lastSelectedView: any;
    menuClosed: boolean;
    currentUser: any;
    search: boolean;
    configuration: any;
    currentAgency: IAgencyDB | null;
    currentCompany: ICompanyDB | null;
    selectedCollaborators: string[];
    selectedDomains: string[];
    selectedView: any;
    collaborators: IUserDB[];
    links: any;
    notifications: INotificationDB[];
    totalUnreadNotifications: number;
    superAdmin: boolean;
    missionDataForJobOffer: any;
    supervisorAgencyId: string;
}

export type StoreAction = (data?: any) => Promise<any>;
export type StoreMutation = (toSet?: any) => Promise<any>;

export type IViewsConfiguration = {
    [key: string]: IViewConfiguration;
};

export interface IViewConfiguration {
    preconfiguredViews?: any[];
    view?: string;
    defaultView?: string;
    fields: {};
}

export interface IField {
    id: string;
    unit: string;
    active: boolean;
}

export interface IGeoLocation {
    lat: number;
    lng: number;
}

export type ObjectID = string;

export type LabelValue = {
    label: string;
    value: any;
    selected?: boolean;
};

export interface IHeader {
    label: string;
    class?: string;
    value?: any;
    filterable?: boolean;
}

import {
    JobExperience,
    MissionStatus,
    Gender,
    UserRole,
    UserStatus,
    MissionCommand,
    WorkerMissionStatus,
    CandidateStatus,
    JobOfferStatus,
    JobService,
    JobServiceStatus,
} from "./data";
// -----------------------------------------------------------------------------------------------
// GLOBAL

export interface IWorkerJob {
    jobId: ObjectID;
    job?: IJobDB;
    experience: JobExperience;
    degreeLevel?: string;
    certifications: Array<ObjectID | string>;
}

export type timestamp = number;
export type DataSet<T> = { [key: string]: T };

export interface DB {
    _id: ObjectID;
    createdAt?: number;
    updatedAt?: number;
}

export type Geolocation = number[];

export interface IAddress {
    name?: string;
    street: string;
    complement?: string;
    city: string;
    citySlug?: string;
    zip: string;
    countryCode?: string;
    location?: Geolocation;
}

export interface IPerson {
    firstname: string;
    lastname: string;
}

// -----------------------------------------------------------------------------------------------
// USER

export interface IUser extends IPerson {
    role?: UserRole;
    username?: string;
    password?: string;
    email?: string;
    picture?: string;
    jobId?: string;
    phone?: string;
    agencyId?: ObjectID;
    companyId?: ObjectID;
    businessSectors?: string[];
    configuration?: UserConfiguration;
    status?: UserStatus;
    firstConnectionToken?: string;
    features?: string[];
}

export interface IUserDB extends IUser, DB {}

// -----------------------------------------------------------------------------------------------
// AGENCY

export interface IAgency {
    name: string;
    nameSlug?: string;
    address?: IAddress;
    brand?: Brand;
    email?: string;
    logo: string | null;
    video: string | null;
    numbers: KeyFigure[];
    description?: string;
    subscriptionType?: any;
    collaborators?: IUserDB[];
    phone?: string;
    siret?: string;
    state?: any;
    website?: string;
    contractManagerType?: string;
    contractManagerIdentifier?: string;
    socialNetworks?: {
        linkedin?: string;
        twitter?: string;
        facebook?: string;
        instagram?: string;
    };
    survey?: {
        mainBusinessSectors: ObjectID[];
        developingBussinessSectors: ObjectID[];
        jobsRequiringMissions: ObjectID[];
        jobsRequiringWorkers: ObjectID[];
        clientSoftwares?: string;
        dematerializationSoftwares?: string;
    };
    delegationDepartments: string[];
    workers?: any;
    alreadyHadMission?: boolean;
    features?: Array<Feature>;
    invitationCode?: string;
    otherCMIdentifiers?: string[];
    evoliaBases?: IEvoliaBase[];
}

export interface IAgencyDB extends IAgency, DB {}

// -----------------------------------------------------------------------------------------------
// COMPANY

export interface ICompany {
    /**
     * #label Nom
     */
    name: string;
    /**
     * #label Nom formaté
     */
    nameSlug?: string;
    /**
     * #label Addresses
     */
    addresses: IAddress[];
    /**
     * #label Siret
     */
    siret?: string;

    /**
     * Evolia fields for synchronization
     */
    agenciesList?: string[];
    evoliaId?: number;

    /**
     * #label Secteur d'activité
     */
    businessSectorId: ObjectID;
    /**
     * #label Description
     */
    description?: string;
    /**
     * #label Note
     */
    note?: string;
    /**
     * #label Chiffres
     */
    numbers: KeyFigure[];
    /**
     * #label Contacts
     */
    contacts: IContact[];
    /**
     * #label Logo
     */
    logo?: string | null;
    /**
     * #label Vidéo
     */
    video?: string | null;
    /**
     * #label Agence
     */
    agencyId: ObjectID;

    email?: string;
    phone?: string;
    website?: string;
    collaborators?: IUserDB[];
    mainAddress?: IAddress;
    naf?: string;

    socialNetworks?: {
        linkedin?: string;
        twitter?: string;
        facebook?: string;
        instagram?: string;
    };

    lastMissions?: IMissionDB[];
    collaboratorId?: string;
}

export type KeyFigure = {
    label: string;
    value: string;
};

export interface IContact extends IPerson {
    role: string;
    phone: string;
    email: string;
    evoliaOrder?: string;
}

export interface ICompanyDB extends ICompany, DB {}

// -----------------------------------------------------------------------------------------------
// BUSINESS SECTOR

export interface IBusinessSector {
    name: string;
    nameSlug?: string;
    pictogram?: string;
}

export interface IBusinessSectorDB extends IBusinessSector, DB {}

// -----------------------------------------------------------------------------------------------
// MISSIONS
export interface IMission {
    name: string;
    nameSlug?: string;
    agencyId?: ObjectID;
    agency?: IAgencyDB;
    client?: ICompanyDB;
    clientId: ObjectID;
    status?: MissionStatus;
    address?: IAddress;
    positions?: MissionPosition[];
    periods?: MissionPeriod[];
    description?: string;
    internalAgencyNotes?: string;
    clientValidation?: boolean;
    presentationMode?: boolean;
    warnings?: MissionWarning[];
    workers?: WorkerMission[];
    alerts?: any;
    realCompanyId?: string;
    realCompany?: ICompanyDB;
    companyOwnerId?: string;

    availableCommands?: any;
    newClient?: ICompany;
    createNewClient?: boolean;
    jobOffers?: IJobOfferMission[];
    sendToEvoliaTimestamp?: timestamp;
}

export type WorkerMission = {
    workerId: ObjectID;
    status: WorkerMissionStatus;
    positionIndex: number;
    firstname?: string;
    lastname?: string;
    periodIndex?: number; // absent pour toutes les périodes
    slot?: string; // absent pour all
    timeSlotIndex?: number; // absent si tous les times slots.
    error?: any; // permet d'indiquer une erreur technique sur ce worker précisément (par exemple, l'impossibilité d'envoyer une notification).
    availableCommands?: Array<MissionCommand>;
    rejectionReason?: string;
    rejectionTimestamp?: timestamp;
    abortReason?: string;
    abortTimestamp?: timestamp;
    propositionTimestamp?: timestamp;
    presentationTimestamp?: timestamp;
    presentationMessage?: string;
    responseTime?: timestamp;
    response?: number;
    // population
    registered?: boolean;
    phone?: string;
    email?: string;
};

export interface IMissionDB extends IMission, DB {}

export type MissionPosition = {
    jobId: ObjectID;
    experience: JobExperience;
    quantity: number;
    certifications: ObjectID[];
    description?: string;
    job?: IJobDB;
    workerDocuments: any;
    justification?: string;
    hiringReason?: string;
};

export interface IHabilitation {
    name: string;
    type: string;
    code: string;
    active?: boolean;
    expirationDate?: number;
}

export type MissionPositionFront = {
    job?: IJobDB;
    jobId?: ObjectID;
    jobName?: string;
    experience: JobExperience;
    quantity: number;
    certifications?: Array<ObjectID | string>;
    description?: string;
    justification?: string;
    hiringReason?: string;
};

export type MissionPeriod = {
    start: timestamp;
    end: timestamp;
    weekendIncluded?: boolean;
    slots: DataSet<TimeSlot[]>;
};

export type TimeSlot = {
    from: number;
    to: number;
};

export type MissionWarning = {
    // TODO: MissionWarning
};

// -----------------------------------------------------------------------------------------------
// INACTIVATED REASON

export interface IInactivatedReason {
    slug: string;
    reason: string;
}

export interface IInactivatedReasonDB extends IInactivatedReason, DB {}

// -----------------------------------------------------------------------------------------------
// MOTIF HIRING REASON

export interface IHiringReason {
    Id: number;
    Code: string;
    Libelle: string;
}

export interface IHiringReasonDB extends IHiringReason, DB {}
//------------------------------------------------------------------------------------------------
// DELETE REASON

export interface IDeletedReason {
    slug: string;
    reason: string;
}

export interface IDeleteReasonDB extends IDeletedReason, DB {}

// -----------------------------------------------------------------------------------------------
// JOB

export interface IJob {
    name: string;
    code?: string;
    PCS?: string;
    synonims: string[];
    slugs: string[];
    verified: boolean;
    businessSectors: Array<ObjectID>;
    notSelectable?: boolean; // si true, ce job ne devra pas être proposé dans less différents select de Job (par contre il pourra être affiché si il est utilisé quelque part).
}

export interface IJobDB extends IJob, DB {}

// -----------------------------------------------------------------------------------------------
// JOB CERTIFICATION

export interface ICertification {
    name: string;
    nameSlug: string;
    jobId: ObjectID;
    verified: boolean;
}

export interface ICertificationDB extends ICertification, DB {}

// -----------------------------------------------------------------------------------------------
// JOB DEGREE LEVEL

export interface IDegreeLevel {
    slug: string;
    name: string;
    rank: number;
}

export interface IDegreeLevelDB extends IDegreeLevel, DB {}

// -----------------------------------------------------------------------------------------------
// UNAVAILABILITIES

export interface IUnavailability {
    date: Date;
    userId: ObjectID;
    night: boolean;
    evening: boolean;
    afternoon: boolean;
    morning: boolean;
    type: string;
    name: string;
}

export interface IUnavailabilityDB extends IUnavailability, DB {}

// -----------------------------------------------------------------------------------------------
// UNAVAILABILITIES_REASON

export interface IUnavailabiliyReason {
    evoliaId?: number;
    code: string;
    label: string;
}

export interface IUnavailabilityReasonDB extends IUnavailabiliyReason, DB {}

// -----------------------------------------------------------------------------------------------
// INTERIM

export interface IInterimJob {
    jobId: ObjectID;
    degreeLevel?: string;
    experience: JobExperience;
    certifications: Array<ObjectID | string>;
}

export interface IInterim extends IUser {
    blacklist?: any;
    stats?: any;
    jobs: Array<IInterimJob>;
    workerDocuments: any;

    nir?: string; // num sécu
    nirKey?: string; // num sécu - clé

    // Evolia fields for synchroinzation
    MatriculeSalarieEvolia?: string;
    agenciesList?: any[];
    agencies?: any[];
    evoliaId?: number;

    managerId?: ObjectID;
    regularAvailabilities: Array<IRegularAvailabilities>;
    unavailabilities?: Array<ObjectID>;
    nightShift: boolean;
    weekendShift: boolean;
    vehicle: boolean;
    enableEmail: boolean;
    enableSMS: boolean;
    phone: string;
    address?: IAddress;
    evoliaAddress?: IAddress;
    cv: CV;
    state?: string;
    maxTravelDistance?: number;
    maxTravelTime?: number;
    carpooling?: boolean;
    publicResume?: string;
    gender?: Gender;
    birthDate?: number;
    birthPlace?: string;
    birthCountry?: string;
    invitationDate?: number;
    oldNotes?: { [key: string]: string };
    presentationText: { [key: string]: string };
    inactiveByAgencies?: any;
    nationalityCode: string;
    missions?: {
        currentMissions?: IMissionDB[];
        futureMissions?: IMissionDB[];
        lastMissions?: IMissionDB[];
    };
    administrativeDocuments?: IAdministrativeDocuments;
    databaseOrigin?: ESearchWorkerModes;
    lastAgencyContact?: { timestamp: number; collaboratorIdentity: string; collaboratorId: ObjectID } | null;
    interviews?: IInterviewDB[];
}

export interface IInterimDB extends IInterim, DB {}

export interface IAdministrativeDocuments {
    habilitations?: IHabilitation[];
    BTPCard?: timestamp;
    medicalCheckup?: timestamp;
    residencePermit?: timestamp;
}

export type CV = {
    path: string | null;
    registrationDate?: timestamp;
    collaboratorId?: ObjectID;
};

export interface IWorkerHabilitation {
    idHabilitation: string;
    habilitationName: string;
    acquisitionDate?: number;
    expirationDate?: number;
    note?: string;
    type: string;
    isValid?: boolean;
}

// -----------------------------------------------------------------------------------------------
// JOB CERTIFICATION

export interface ICertification {
    name: string;
    nameSlug: string;
    jobId: ObjectID;
    verified: boolean;
}

export interface ICertificationDB extends ICertification, DB {}

// -----------------------------------------------------------------------------------------------
// CONFIGURATION

export type UserConfiguration = {
    views?: DataSet<ViewConfiguration>;
};

export type GlobalConfiguration = {
    jobs: Array<any>; // Completed IJobDB
    nationalities: Array<INationality>;
    businessSectors: Array<IBusinessSectorDB>;
    views: DataSet<ViewConfiguration>;
};

export type ViewConfiguration = {
    preconfiguredViews: Array<PreconfiguredView>;
};

export type PreconfiguredView = {
    name: string;
    nameSlug: string;
    type: string;
    filters: DataSet<any>;
};

// -----------------------------------------------------------------------------------------------
// NOTIFICATION

export interface INotification {
    text?: string;
    title?: string;
    link?: any;
    type: NotificationType;
    items: INotificationItem;
    data: any;
    level: NotificationLevel;
    status: NotificationStatus;
    terminationTimestamp?: number;
}

export interface INotificationDB extends INotification, DB {}

export interface INotificationItem {
    agencyId?: ObjectID;
    collaborators?: ObjectID[];
    workers?: ObjectID[];
    clients?: ObjectID[];
    missions?: ObjectID[];
}

export type NotificationSender = {
    agencyId?: ObjectID;
    companyId?: ObjectID;
    temporaryWorkerId?: ObjectID;
    collaboratorId?: ObjectID;
};

export interface IRawNotification {
    sender: NotificationSender;
    type: NotificationType;
    data: any;
    timestamp: timestamp;
    noSenderNotification?: boolean;
}

// -----------------------------------------------------------------------------------------------
// DEPARTMENT

export interface IDepartment {
    code: string;
    name: string;
    regionCode: string;
}

export interface IDepartmentDB extends IDepartment, DB {}

// -----------------------------------------------------------------------------------------------
// REGION

export interface IRegion {
    code: string;
    name: string;
}

export interface IRegionDB extends IRegion, DB {}

// -----------------------------------------------------------------------------------------------
// Regular Availabilities

export interface IRegularAvailabilities {
    morning: boolean;
    afternoon: boolean;
    evening: boolean;
    night: boolean;
}

export interface IWorkerStats {
    missions: {
        proposed: number;
        accepted: number;
        declined: number;
        notconfirmed: number;
        confirmed: number;
        aborted: number;
        rejectedByAgency: number;
    };
    hoursWorkedCurrentMonth: number;
    hoursWorkedCurrentYear: number;
    averageResponseTime: number | null;
    daysSinceLastMission: number | null;
    daysBeforeNextMission: number | null;
    upcomingMissionNumber: number;
    daysLongestMission: number;
}

// -----------------------------------------------------------------------------------------------
// JOB OFFER

export interface IJobOffer {
    stats?: any;
    agencyId: ObjectID;
    ownerId: ObjectID; // collaborator id
    name: string;
    availableCommands?: JobOfferCommand[];
    nameSlug?: string;
    services: DataSet<JobServiceConfiguration>;
    jobId: ObjectID;
    certifications?: Array<ICertificationDB>;
    experience?: JobExperience;
    address: Partial<IAddress>;
    region?: string;
    department?: string;
    description: string;
    missionId?: ObjectID;
    clientId?: ObjectID;
    status?: JobOfferStatus;
    mission?: IMission;
    timestamp?: DataSet<timestamp>;
    missionStartDate?: timestamp;
    businessSectors?: Array<string>;
    businessSectorFull?: any;
    functions?: Array<string>;
    functionFull?: any;
    jobs?: any;
    job?: any;
    jobType: string;
    jobContract: string;
    jobStatus: string;
    jobDuration: string;
    applicantProfile: string;
    reference?: string;
    applicantDegree?: string;
    applicantExperience?: string;
    salaryFrom?: string;
    salaryTo?: string;
    salaryPer?: string;
    salaryCurrency?: string;
    offerUrl?: string;
    offerUrlEdit?: string;
    candidates: Array<JobOfferCandidates>;
    postingDate?: timestamp;
}

export interface JobOfferCandidates {
    idCandidate: ObjectID;
    status: string;
}

export interface IJobOfferDB extends IJobOffer, DB {}

export type JobServiceConfiguration = {
    jobBoards: Array<string>;
    active: boolean;
    activationDate?: timestamp;
    status?: JobServiceStatus;
    offer?: any;
};

export type CandidateItem = {
    value: any;
    validated: boolean;
};

export type TalentplugItem = {
    label: string;
    code: number;
};

export interface ITalentplugIndustry {
    job_industry: string;
    ID: number;
}

export interface ITalentplugIndustryDB extends ITalentplugIndustry, DB {}

export interface ITalentplugFunction {
    job_function: string;
    ID: number;
    ParentID: number;
}

export interface ITalentplugFunctionDB extends ITalentplugFunction, DB {}

export interface IMessageTemplate {
    name: string;
    nameSlug: string;
    language: "fr"; // pour l'instant on n'a qu'une langue
    text: string;
    type: MessageTemplateType;
    agencyId?: ObjectID; // pour l'instant on n'aura jamais ce paramètre.
}

export interface IMessageTemplateDB extends IMessageTemplate, DB {}

export type CandidateParsed = {
    firstname?: CandidateItem;
    lastname?: CandidateItem;
    email?: CandidateItem;
    phone?: CandidateItem;
    address?: CandidateItem;
    picture?: CandidateItem;
    cv_path?: CandidateItem;
    jobs?: Array<CandidateItem>;
    birthDate?: CandidateItem;
    birthPlace?: CandidateItem;
    birthCountry?: CandidateItem;
    keywords?: CandidateItem;
};

export interface ICandidate {
    firstname?: string;
    lastname?: string;
    email?: string;
    phone?: string;
    address?: IAddress;
    picture?: string | null;
    cv: CV;
    jobOffers?: Array<any>;
    sourceJobService?: JobService;
    origin?: string;
    source?: string;
    workerId?: ObjectID;
    agencies?: IWorkerAgency[];
    status: CandidateStatus;
    candidateLogs?: Array<CandidateLog>;
    timestamp?: DataSet<timestamp>;
    jobs?: Array<IWorkerJob>;
    birthDate?: number;
    birthPlace?: string;
    birthCountry?: string;
    fillingRate?: number;
    keywords?: Array<string>;
    dataAnalysis?: any;
    candidateParsed?: CandidateParsed;
    interviews: Array<IInterviewDB>;
    availableCommands?: any;
    vehicle?: boolean;
    maxTravelDistance?: number;
    regularAvailabilities: Array<IRegularAvailabilities>;
    nightShift: boolean;
    weekendShift: boolean;
    teamPreference: TeamPreference;
    schedule: Schedule;
    contractPreference: ContractPreference;
    environment: Environment;
    nationalityCode?: string;
    currentAgency?: IWorkerAgency;
    interimAgencies?: Partial<IWorkerAgency[]>;
    role: ECandidateRole;
}

export interface IWorkerAgency {
    agencyId: ObjectID;
    agencyName: string;
    candidateStatus: CandidateStatus;
    deleted?: boolean;
    inactive?: boolean;
    offersApplications?: IOfferApplication[];
}

export interface IOfferApplication {
    candidateStatus: CandidateStatus;
    offerId: ObjectID;
    offerName: string;
}
export interface Environment {
    negativeCold: boolean;
    positiveCold: boolean;
    noise: boolean;
    smells: boolean;
    dust: boolean;
    sustainedPace: boolean;
    extendedStandingPosition: boolean;
    heavyLoads: boolean;
}

export interface Schedule {
    "2x8": boolean;
    "3x8": boolean;
    "4x8": boolean;
    day: boolean;
}

export interface ContractPreference {
    CDI: boolean;
    CDD: boolean;
    INTERIM: boolean;
    VACATION: boolean;
}

export type KeysEnum<T> = { [P in keyof Required<T>]: true };

export interface ICandidateDB extends ICandidate, DB {}

export interface IInterview {
    date: timestamp;
    candidateId: ObjectID;
    collaboratorId: ObjectID;
    candidateMessage?: string;
    collaboratorMessage?: string;
    closed: boolean;
    comment?: string;
    start: number;
    end: number;
}

export interface IInterviewDB extends IInterview, DB {}

export type CandidateLog = {
    type: CandidateLogType;
    timestamp: timestamp;
    collaborator?: string;
    date?: string;
    collaboratorId: ObjectID;
    message?: string;
    internalComment?: string;
    rejectedBy?: string;
    statusAfter?: CandidateStatus;
    interviewDate?: string;
    interviewHours?: string;
    interviewId?: string;
};

export const jobType: { [key: string]: TalentplugItem } = {
    full: {
        label: "Temps plein",
        code: 40,
    },
    partial: {
        label: "Temps partiel",
        code: 41,
    },
    daily: {
        label: "Journalier",
        code: 43,
    },
};

export const jobContract: { [key: string]: TalentplugItem } = {
    cdi: {
        label: "CDI",
        code: 21,
    },
    cdd: {
        label: "CDD",
        code: 22,
    },
    interim: {
        label: "Intérim",
        code: 23,
    },
    intership: {
        label: "Stage",
        code: 24,
    },
    vie: {
        label: "VIE",
        code: 25,
    },
    alternance: {
        label: "Alternance",
        code: 26,
    },
    freelance: {
        label: "Freelance",
        code: 27,
    },
    statutory: {
        label: "Statutaire",
        code: 28,
    },
    franchise: {
        label: "Franchise",
        code: 29,
    },
};

export const jobStatus: { [key: string]: TalentplugItem } = {
    employee: {
        label: "Employé",
        code: 30,
    },
    operator: {
        label: "Opérateur",
        code: 31,
    },
    worker: {
        label: "Ouvrier",
        code: 33,
    },
    skilledWorker: {
        label: "OuvrierSpécialisé",
        code: 34,
    },
    technician: {
        label: "Technicien",
        code: 35,
    },
    supervisor: {
        label: "Agent de maîtrise",
        code: 36,
    },
    engineer: {
        label: "Ingénieur",
        code: 37,
    },
    executive: {
        label: "Cadre",
        code: 38,
    },
    seniorManager: {
        label: "Cadre dirigeant",
        code: 39,
    },
};

export const jobDuration: { [key: string]: TalentplugItem } = {
    "1": {
        label: "1 mois",
        code: 1528,
    },
    "2": {
        label: "2 mois",
        code: 1529,
    },
    "3": {
        label: "3 mois",
        code: 1530,
    },
    "4": {
        label: "4 mois",
        code: 1531,
    },
    "5": {
        label: "5 mois",
        code: 1532,
    },
    "6": {
        label: "6 mois",
        code: 1533,
    },
    "7": {
        label: "7 mois",
        code: 1534,
    },
    "8": {
        label: "8 mois",
        code: 1535,
    },
    "9": {
        label: "9 mois",
        code: 1536,
    },
    "10": {
        label: "10 mois",
        code: 1537,
    },
    "11": {
        label: "11 mois",
        code: 1538,
    },
    "12": {
        label: "12 mois",
        code: 1539,
    },
    "13": {
        label: "13 mois",
        code: 1540,
    },
    "14": {
        label: "14 mois",
        code: 1541,
    },
    "15": {
        label: "15 mois",
        code: 1542,
    },
    "16": {
        label: "16 mois",
        code: 1543,
    },
    "17": {
        label: "17 mois",
        code: 1544,
    },
    "18": {
        label: "18 mois",
        code: 1545,
    },
    "19": {
        label: "19 mois",
        code: 1546,
    },
    "20": {
        label: "20 mois",
        code: 1547,
    },
    "21": {
        label: "21 mois",
        code: 1548,
    },
    "22": {
        label: "22 mois",
        code: 1549,
    },
    "23": {
        label: "23 mois",
        code: 1550,
    },
    "24": {
        label: "24 mois",
        code: 1554,
    },
};

export const applicantDegree: { [key: string]: TalentplugItem } = {
    bepCap: {
        label: "BEP/CAP",
        code: 10,
    },
    bac: {
        label: "BAC",
        code: 12,
    },
    bacPro: {
        label: "BAC Professionnel",
        code: 13,
    },
    "bac+2": {
        label: "BAC+2",
        code: 14,
    },
    "bac+3": {
        label: "BAC+3",
        code: 15,
    },
    "bac+4": {
        label: "BAC+4",
        code: 16,
    },
    "bac+5": {
        label: "BAC+5",
        code: 17,
    },
    "bac+6": {
        label: "BAC+6",
        code: 18,
    },
    doctor: {
        label: "Doctorat",
        code: 19,
    },
    researcher: {
        label: "Chercheur",
        code: 20,
    },
};

export const applicantExperience: { [key: string]: TalentplugItem } = {
    "0-1": {
        label: "0-1 an",
        code: 3,
    },
    "1-2": {
        label: "1-2 ans",
        code: 4,
    },
    "2-5": {
        label: "2-5 ans",
        code: 5,
    },
    "5-7": {
        label: "5-7 ans",
        code: 6,
    },
    "7-10": {
        label: "7-10 ans",
        code: 7,
    },
    "10-15": {
        label: "10-15 ans",
        code: 8,
    },
    ">15": {
        label: "+ de 15 ans",
        code: 9,
    },
};

export const salaryFrom: { [key: string]: TalentplugItem } = {
    "0": {
        label: "0",
        code: 1556,
    },
    "5000": {
        label: "5000",
        code: 1557,
    },
    "10000": {
        label: "10000",
        code: 1558,
    },
    "15000": {
        label: "15000",
        code: 1559,
    },
    "20000": {
        label: "20000",
        code: 1560,
    },
    "25000": {
        label: "25000",
        code: 1561,
    },
    "30000": {
        label: "30000",
        code: 1562,
    },
    "35000": {
        label: "35000",
        code: 1563,
    },
    "40000": {
        label: "40000",
        code: 1564,
    },
    "45000": {
        label: "45000",
        code: 1565,
    },
    "50000": {
        label: "50000",
        code: 1566,
    },
    "55000": {
        label: "55000",
        code: 1567,
    },
    "60000": {
        label: "60000",
        code: 1568,
    },
    "70000": {
        label: "70000",
        code: 1569,
    },
    "90000": {
        label: "90000",
        code: 1570,
    },
    "110000": {
        label: "110000",
        code: 1571,
    },
    "130000": {
        label: "130000",
        code: 1572,
    },
    "150000": {
        label: "150000",
        code: 1573,
    },
    ">150000": {
        label: "110000",
        code: 1574,
    },
};

export const salaryTo: { [key: string]: TalentplugItem } = {
    "0": {
        label: "0",
        code: 1575,
    },
    "5000": {
        label: "5000",
        code: 1576,
    },
    "10000": {
        label: "10000",
        code: 1577,
    },
    "15000": {
        label: "15000",
        code: 1578,
    },
    "20000": {
        label: "20000",
        code: 1579,
    },
    "25000": {
        label: "25000",
        code: 1580,
    },
    "30000": {
        label: "30000",
        code: 1581,
    },
    "35000": {
        label: "35000",
        code: 1582,
    },
    "40000": {
        label: "40000",
        code: 1583,
    },
    "45000": {
        label: "45000",
        code: 1584,
    },
    "50000": {
        label: "50000",
        code: 1585,
    },
    "55000": {
        label: "55000",
        code: 1586,
    },
    "60000": {
        label: "60000",
        code: 1587,
    },
    "70000": {
        label: "70000",
        code: 1588,
    },
    "90000": {
        label: "90000",
        code: 1589,
    },
    "110000": {
        label: "110000",
        code: 1590,
    },
    "130000": {
        label: "130000",
        code: 1591,
    },
    "150000": {
        label: "150000",
        code: 1592,
    },
    ">150000": {
        label: "110000",
        code: 1593,
    },
};
export const salaryCurrency: { [key: string]: TalentplugItem } = {
    eur: {
        label: "EUR (€)",
        code: 51,
    },
};

export const salaryPer: { [key: string]: TalentplugItem } = {
    year: {
        label: "ANNEE",
        code: 44,
    },
    month: {
        label: "MOIS",
        code: 45,
    },
    week: {
        label: "SEMAINE",
        code: 47,
    },
    day: {
        label: "JOUR",
        code: 48,
    },
    hour: {
        label: "HEURE",
        code: 49,
    },
};

/**
 * Synchronizer:
 * - ITracker_synchro: generique interface
 * - SynchroStateType: Enum of the possible tracker states
 * - ITracker_interimsSynchro: interimsSynchro tracker
 * - ITracker_companiesSynchro: companiesSynchro tracker
 * - AgencyIdentifiers: Interface used to link Evolia identifiers: an agency code to an agency Id
 */
export interface ITracker_synchro {
    agencyCode: string;
    evoliaId: number;
    state: SynchroStateType;
    createdAt: number;
}

export interface ITracker_synchroDB {
    createdAt: number;
    updatedAt: number;
}

export enum SynchroStateType {
    created = "created",
    inProgress = "in_progress",
    terminated = "terminated",
    error = "error",
}

export interface ITracker_interimsSynchro extends ITracker_synchro {
    nbEvoliaInterims: number;
    nbActiveEvoliaInterims: number;
    nbCreatedInterims: number;
    nbUpdatedInterims: number;
    nbLinkedInterims: number;
}

export interface ITracker_interimsSynchroDB extends ITracker_interimsSynchro, ITracker_synchroDB {}

export interface ITracker_companiesSynchro extends ITracker_synchro {
    nbEvoliaCompanies: number;
    nbCreatedCompanies: number;
    nbUpdatedCompanies: number;
    nbLinkedCompanies: number;
}

export interface ITracker_companiesSynchroDB extends ITracker_companiesSynchro, ITracker_synchroDB {}

export interface AgenceIdentifiers {
    agencyCode: string;
    agencyId: number;
}

export interface INationality {
    code: string;
    name: string;
}

export interface INationalityDB extends INationality, DB {}

export interface ICountry {
    code: string;
    name: string;
}

export interface ICountryDB extends ICountry, DB {}

export interface IJobOffersWorker {
    _id: ObjectID | string;
    firstname: string;
    lastname: string;
    addedToJobOfferAt: timestamp;
    isInterim: boolean;
    statusInJobOffer: ECandidateOfferStatus;
    globalStatus: any; // En attendant de mettre le statut global
    phone: string;
    sourceInJobOffer: JobService[];
    candidateStatus: EWorkerCandidateStatus;
    role: string;
    origin: string;
    source: string;
    agencies: ({ agencyName: string; profileStatus: string; candidateStatus: CandidateStatus } & IAgency)[];
    createdAt: number;
    isIdentification?: boolean;
    cv: CV;
    jobs: IWorkerJob[];
    address: IAddress;
    email: string;
    isVehicle: boolean;
    availableCommands: any;
    offerApplications: IOfferApplication[];
}

export interface IWorkerJobOfferByStatus {
    workers: IJobOffersWorker[];
    status: ECandidateOfferStatus;
}

export interface IOfferApplication {
    offerId: ObjectID;
    jobId: ObjectID;
    applicationDate: timestamp;
    client: string;
}

export interface IGeocodeJSON {
    type: string;
    geocoding: {
        version: string;
        licence: string;
        attribution: string;
        query: string;
    };
    features: IGeocodeJSONFeature;
}

export interface IGeocodeJSONFeature {
    properties: IGeocodeJSONProperties;
    type: string;
    geometry: {
        coordinates: number[];
        type: string;
    };
}

export interface IGeocodeJSONProperties {
    type: string;
    accuracy: number;
    label: string;
    name: string;
    housenumber: string;
    street: string;
    locality: string;
    postcode: string;
    city: string;
    district: string | null;
    county: string | null;
    state: string | null;
    country: string;
    admin: {
        level2: string;
        level4: string;
        level6: string;
    };

    geohash: string;
}

export interface IFilter {
    sectionName?: string;
    sectionIcon?: string;
    type: "checkbox" | "radio";
    selectedAll?: boolean;
    items: IItemFilter[];
}

export interface IItemFilter {
    label: string;
    value: string;
    selected: boolean;
}

export interface ILog<IContent> {
    type: ELogType;

    relatedTo: ObjectID;
    collectionOrigin: COLLECTIONS;

    collaborator: {
        _id: ObjectID;
        name: string;
    };
    agency: {
        _id: ObjectID;
        name: string;
    };

    timestamp: timestamp;

    message?: string;
    content: IContent;
}

export interface ILogDB<TypeContent> extends ILog<TypeContent>, DB {}

export interface IWorkerLogsContent {
    statusAfter?: CandidateStatus;
    interviewId?: ObjectID;
    internalComment?: string;
    contactType?: ECandidateContact;
    originAgencies?: ILoggerAgency[];
}

export interface ILoggerLog {
    _id: string;
    title: string;
    timestamp: number;
    icon?: string;
    description?: { type: "block" | "textarea"; text: string; editable: boolean }[];
    commentary?: string;
}
export interface IIdentificationResultCommonAgency {
    _id?: ObjectID;
    name: string;
    address?: IAddress;
    deleted: boolean;
    inactive: boolean;
    candidateStatus?: string;
    inactiveReason?: {
        timestamp: timestamp;
        reasonSlug: string;
        complement?: string;
    };
}

export interface IIdentificateResultCommonWorkerSchema {
    _id: ObjectID;
    firstname: string;
    lastname: string;
    birthDate: timestamp;
    birthPlace: string;
    email: string;
    phone: string;
    address: IAddress;
    nir: string;
    nirKey: string;
    agencies: IIdentificationResultCommonAgency[];
    evoliaId?: string;
    evoliaAddress?: IAddress;
    candidateId?: ObjectID;
    workerId?: ObjectID;

    databaseOrigin: EWorkerDatabaseOrigin;
    nameSlugScore?: number;
    matchingScore?: number;
    nationalityCode: string;
    birthCountry: string;
    cv: CV;
    picture: string;
    source: string;
    jobName: string;
    jobOfferName: string;
    duration: number;
}
export interface ILoggerAgency {
    _id: ObjectID;
    name: string;
}

export interface IJobOfferMission {
    _id?: ObjectID;
    name?: string;
    status?: JobOfferStatus;
    workerToTreated?: number;
    createdAt?: timestamp;
}
export interface IEvoliaBase {
    evoliaId: number;
    evoliaBaseName: string;
}
