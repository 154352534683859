
import {Component} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ModalMixin from "@/components/modal/ModalMixin.vue";

@Component({
    name: "RedirectionModal"
})
export default class RedirectionModal extends mixins(ModalMixin) {
    name = "RedirectionModal";
}
